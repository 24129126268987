<template>
    <section class="filters pb-1 px-2"> 
        <b-row align-h="between">
            <b-col lg="5" md="5" sm="5" class="col-12 mb-1 mb-sm-0">
                <div class="d-flex justify-center-center align-items-center">
                    <span class="mr-1" for="tableSize">Mostrar</span>
                    <v-select 
                        id="tableSize"
                        v-model="defaultSize"
                        @input="fireEventFilter(false, true)"
                        :options="optionsSize"
                        :clearable="false"
                        class="customizeDifferentSpace"
                    >
                        <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                </div>
            </b-col>
            <b-col lg="7" md="7" sm="7" v-if="showInputFilter">
                <div class="d-flex justify-center-center align-items-center">
                    <span class="mr-1" for="search-input">Buscar</span>
                    <b-form-input 
                        id="search-input" 
                        v-model="searchTerm"
                        @input="filterdadosTable"
                    />
                </div>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { BRow, BCol, BFormInput} from 'bootstrap-vue';
import { getListFilter } from '@core/utils/store/getStore';
import { setListFilter } from '@core/utils/store/setStore';
import vSelect from 'vue-select';

export default {
    components: {
        BRow, BCol, BFormInput, vSelect
    },

    props: {
        filter: {
            required: true,
            type: Boolean
        },
        showInputFilter: {
            required: false,
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            defaultSize: 10,
            optionsSize: [10, 25, 50, 100],
            searchTerm: '',
            timeID: null
        }
    },

    mounted() {
        this.fireEventFilter(true, false);
    },

    methods: {
        filterdadosTable() {
            if(this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
                clearTimeout(this.timeID);

                this.timeID = setTimeout(() => {
                    this.fireEventFilter(false, true);
                }, 500);
            }
        },

        fireEventFilter(first = false, changeSize = false) {
            let listFilter =  {
                searchTerm: this.searchTerm,
                defaultSize: this.defaultSize,
                route: this.$router.currentRoute.name
            }       

            if(changeSize) { listFilter.currentPage = 1; }
            if(!first) { setListFilter(listFilter); }

            const savedFilter = getListFilter()

            if(savedFilter && savedFilter.route == this.$router.currentRoute.name) {
                this.searchTerm = savedFilter.searchTerm ?? this.searchTerm;
                this.defaultSize = savedFilter.defaultSize ?? this.defaultSize;
            } else {
                setListFilter(listFilter)
                this.$emit('filterTable', listFilter);
            }

            this.$emit('filterTable', savedFilter);
        }
    },

    watch: {
        filter() {
            this.fireEventFilter();
        },

        defaultSize() {
            this.$emit('defaultSizeChanged')
        }
    }
}
</script>

